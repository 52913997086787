/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('ReportSummary', ['$http', 'apiPrefixUrl', 'utils',
    function($http, apiPrefixUrl, utils) {
      var url = apiPrefixUrl + '/appnexus/report/summary',
        that = this;

      this.getReportSummary = function(params) {
        // Needed params: user_id, from_date, to_date, page, order_key, order_type
        return new Promise(function(resolve, reject){
          var options = {
            params: params
          };
          $http.get(url, options).then(resolve, reject);
        });
      };
    }
  ])
})();

