/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('LineItemService',
    ['$http', 'lineitemPrefixUrl', '$cacheFactory',
    function($http, lineitemPrefixUrl, $cacheFactory) {
      var that = this,
        httpCache = $cacheFactory.get('$http'),
        getLineItemsUrl = lineitemPrefixUrl + '/line_item/',
        getLineItemsBudgetsUrl = lineitemPrefixUrl + '/line_item_budgets/',
        setPriceUrl = lineitemPrefixUrl + '/setprice';

      this.fetchLineItems = function() {
        return new Promise(function(resolve, reject){
            var options = {cache: true};

            $http.get(getLineItemsUrl, options).then(function(resp) {
              var lineitems = {};
              var rawToObject = function(row, index){
                var lineitem = {
                  advertiser_id: row.advertiser_id.replaceAll('-',''),
                  id: row.id.replaceAll('-',''),
                  name: row.name,
                  line_item_type: row.line_item_type,
                  revenue_type: row.revenue_type,
                  revenue_value: row.revenue_value ? parseFloat(row.revenue_value.value) : null,
                  state: row.state
                };
                lineitems[lineitem.id] = lineitem;
              };
              angular.forEach(resp.data.line_items, rawToObject);
              resolve(lineitems);
            });
        });
      };

      this.setPrice = function(line_item_id, revenue_value) {
        return new Promise(function(resolve, reject){
          var params = {
              id: line_item_id,
              revenue_value: revenue_value
            };

          $http.post(setPriceUrl, params).then(function(resp) {
            httpCache.remove(getLineItemsUrl);
            resolve(resp);
          });
        });
      };
    }
  ])
})();

